import React from 'react'
import PropTypes from 'prop-types'
import { CategoryButton } from '../../courses/filter-categories/style'

const ArticlesFilter = ({
  userGroups,
  selectedUserGroup,
  setSelectedUserGroup,
}) => {
  const handleUserGroupClick = (slug) => {
    if (selectedUserGroup.includes(slug)) {
      setSelectedUserGroup('')
    } else if (!selectedUserGroup.includes(slug)) {
      setSelectedUserGroup(slug)
    }
  }

  return (
    <>
      <div
        className={'relative bg-white w-screen py-5 lg:xl-container lg:mx-auto'}
      >
        <div className={'flex gap-x-1 flex-wrap w-full lg:w-max'}>
          <p
            className={
              'font-semibold text-base basis-full mb-2.5 text-blue-dark xl-container lg:p-0 '
            }
          >
            Passer for
          </p>
          {userGroups && (
            <div
              className={
                'overflow-x-auto flex gap-x-1 py-4 mb-2 px-4 sm:px-6 md:px-8 lg:px-0'
              }
            >
              <CategoryButton
                active={!selectedUserGroup}
                key={'Alle'}
                value={''}
                onClick={() => setSelectedUserGroup('')}
              >
                Alle
              </CategoryButton>
              {userGroups.map((item) => (
                <CategoryButton
                  active={selectedUserGroup.includes(item.slug)}
                  key={item.slug}
                  value={item.slug}
                  onClick={() => handleUserGroupClick(item.slug)}
                >
                  {item.name}
                </CategoryButton>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ArticlesFilter

ArticlesFilter.propTypes = {
  userGroups: PropTypes.arrayOf(PropTypes.object),
  selectedUserGroup: PropTypes.string,
  setSelectedUserGroup: PropTypes.func,
  setOpen: PropTypes.func,
  isOpen: PropTypes.bool,
}
