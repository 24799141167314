import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Banner from '../components/banner'
import SEO from '../components/seo'
import { selectedFilterEvent } from '../components/google-tag-manager/events'
import Articles from '../components/articles'

const CasesTemplate = ({ data, location }) => {
  const {
    allWpCase: { nodes: cases },
    allWpUserGroup: { nodes: userGroups },
    wpPage: page,
  } = data

  const banner = page.template.componentBanner

  const [selectedUserGroup, setSelectedUserGroup] = useState('')

  const doesCourseMatchSelectedUserGroup = (userGroup) => {
    return userGroup.nodes.some((item) => selectedUserGroup.includes(item.slug))
  }

  useEffect(() => {
    selectedUserGroup
      ? selectedFilterEvent(selectedUserGroup)
      : selectedFilterEvent('alle')
  }, [selectedUserGroup])

  const applyFilter = (list) => {
    if (selectedUserGroup === '') {
      return cases
    }
    return list.reduce((reducer, item) => {
      if (doesCourseMatchSelectedUserGroup(item.userGroups)) {
        reducer.push(item)
      }
      return reducer
    }, [])
  }
  return (
    <Layout location={location}>
      <SEO
        title={page.title}
        metaTitle={page.seo.title}
        description={page.seo.metaDesc}
      />
      <Banner
        image={banner.bannerImage}
        title={page.title}
        breadcrumbs={page.seo.breadcrumbs}
        excerpt={banner.excerpt}
      />
      <Articles
        list={applyFilter(cases).map((item) => ({
          image: item.featuredImage.node,
          title: item.title,
          date: item.date,
          group: item.userGroups.nodes.name,
          uri: item.uri,
          tags: item.userGroups.nodes,
          excerpt: item.excerpt,
          wordCount: item.templateCase, //Check if this breaks filtering
        }))}
        selectedUserGroup={selectedUserGroup}
        setSelectedUserGroup={setSelectedUserGroup}
        userGroups={userGroups}
        noCaseFoundText={page.template.templateCases.noCaseFoundText}
      />
    </Layout>
  )
}

export default CasesTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      uri
      title
      template {
        ... on WpCasesTemplate {
          componentBanner {
            bannerImage {
              title
              publicUrl
              sourceUrl
            }
            excerpt
          }
          templateCases {
            noCaseFoundText
          }
        }
      }
      seo {
        breadcrumbs {
          text
          url
        }
        metaDesc
        title
      }
    }
    allWpCase(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        uri
        excerpt
        date
        userGroups {
          nodes {
            taxonomyName
            name
            slug
          }
        }
        featuredImage {
          node {
            publicUrl
            title
            altText
          }
        }
        templateCase {
          introductionText
          summaryWidget {
            summaryContent
            summaryHeading
          }
          mainContent {
            ... on WpCase_Templatecase_MainContent_Wysiwyg {
              wysiwygContent
              fieldGroupName
            }
            ... on WpCase_Templatecase_MainContent_Media {
              fieldGroupName
              media {
                mediaSelect
                image {
                  altText
                  sourceUrl
                  publicUrl
                  caption
                }
                video {
                  sourceUrl
                  publicUrl
                  title
                  altText
                  caption
                }
              }
            }
            ... on WpCase_Templatecase_MainContent_Heading {
              fieldGroupName
              fontSize
              fontWeight
              headingTag
              headingText
            }
            ... on WpCase_Templatecase_MainContent_Button {
              fieldGroupName
              buttonList {
                buttonLink {
                  target
                  title
                  url
                }
                buttonVariant
              }
            }
          }
        }
      }
    }
    allWpUserGroup {
      nodes {
        name
        slug
      }
    }
  }
`
