import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import ArticleCard from './article/card'
import FilterCategories from './filter-categories'
import { Frown } from '../../images/frown'
import {
  filterDoesNotHavesMatchesEvent,
  filterHasMatchesEvent,
} from '../google-tag-manager/events'

const Articles = ({
  list,
  selectedUserGroup,
  setSelectedUserGroup,
  userGroups,
  noCaseFoundText,
}) => {
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    list.length > 0
      ? filterHasMatchesEvent('artikkel')
      : filterDoesNotHavesMatchesEvent('artikkel')
  }, [list])

  return (
    <>
      <FilterCategories
        isOpen={isOpen}
        setOpen={setOpen}
        selectedUserGroup={selectedUserGroup}
        setSelectedUserGroup={setSelectedUserGroup}
        userGroups={userGroups}
      />
      <div className={'xl-container mx-auto'}>
        {list.length === 0 && (
          <div
            className={
              'flex flex-col items-center justify-center w-full gap-y-2 pt-8'
            }
          >
            <span
              className={
                'rounded-full bg-blue-lighter h-10 w-10 flex items-center justify-center'
              }
            >
              <Frown />
            </span>
            <p className={'text-lg text-blue-dark'}>{noCaseFoundText}</p>
          </div>
        )}
      </div>
      <div
        className={
          'xl-container gap-8 mx-auto grid grid-cols-1 md:grid-cols-2 mb-32'
        }
      >
        {list.map((item, index) => (
          <ArticleCard
            key={index}
            title={item.title}
            image={item.image}
            link={item.uri}
            excerpt={item.excerpt}
            uri={item.uri}
            tags={item.tags}
            wordCount={item.wordCount}
          />
        ))}
      </div>
    </>
  )
}

export default Articles

Articles.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
}
