import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import { GetAllText, ReadingTime } from '../../../utils/reading-time'

const ArticleCard = ({ title, image, tags, excerpt, uri, wordCount }) => {
  const time = wordCount && ReadingTime(GetAllText(wordCount))
  return (
    <Link to={uri}>
      <div className={'flex flex-col w-full gap-4'}>
        <div>
          <img
            className={'rounded-xl w-full aspect-[1.79/1] object-cover'}
            src={image.publicUrl}
            alt={image.altText ? image.altText : ''}
          />
        </div>
        <div className={'flex flex-wrap gap-y-2 gap-x-1'}>
          {tags.map((item, index) => (
            <span
              key={index}
              className={
                'bg-blue-lighter text-blue-dark rounded-full pt-2 px-3 pb-2.5 w-max'
              }
            >
              {item.name}
            </span>
          ))}
        </div>
        <h3 className={'text-3xl md:text-4xl font-bold text-blue-dark'}>
          {title}
        </h3>
        <p className={'font-medium text-blue-dark text-sm'}>{time && time}</p>
        <div className={'flex flex-col justify-between gap-4 h-full'}>
          <div className={'text-base lg:text-lg text-blue-dark'}>
            {parse(excerpt)}
          </div>
          <button
            className={
              'text-base text-blue-dark font-medium border-orange-main border-b-2 w-max'
            }
          >
            Les mer
          </button>
        </div>
      </div>
    </Link>
  )
}

export default ArticleCard

ArticleCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
  tags: PropTypes.arrayOf(PropTypes.object),
  excerpt: PropTypes.string,
  uri: PropTypes.string,
}
